const students_presences_check = () => {
  const students = document.querySelectorAll("#student");
  Array.from(students).forEach((student) => {
    student.addEventListener("click", function (event) {
      if (student.className == "text-dark") {
        student.className = "text-danger";
      } else {
        student.className = "text-dark";
      }
    });
  });
};

export { students_presences_check };
